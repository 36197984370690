import InstitutionEntity from './InstitutionEntity'

export interface Organization {
  id: string
  type: string
  organisationId: string
  application: string
}

export interface UserEntity {
  id: string
  firstname: string
  lastname: string
  username: string
  passwordExpirationDate?: Date
  email?: string
  type?: string
  fonction?: string
  roles?: string[]
  UPN?: string
  officePhone?: string
  mobilePhone?: string
  createdAt?: Date
  state: string
  lastConnexionDate?: Date
  companyId: string
  company?: InstitutionEntity
  organizations: Organization[]
  preferences?: ContactPreference
  couldBeImpersonated?: boolean
  updatedBy?: UserUpdater
  accessDemands?: boolean
  accessDocs?: boolean
  isSuperAdminTech?: boolean
}

export interface UserUpdater {
  firstname: string
  lastname: string
}

export const StatusOptions = [
  {
    value: 'beingCreated',
    label: 'En cours de création',
  },
  {
    value: 'activated',
    label: 'Actif',
  },
  {
    value: 'deactivated',
    label: 'Inactif',
  },
  {
    value: 'blocked',
    label: 'Bloqué',
  },
]

export const DEFAULT_USERINFOS = {
  id: '',
  username: '',
  firstname: '',
  lastname: '',
  email: '',
  type: '',
  fonction: '',
  roles: [''],
  UPN: '',
  officePhone: '',
  mobilePhone: '',
  createdAt: new Date(),
  state: '',
  lastConnexionDate: new Date(),
  passwordExpirationDate: new Date(),
  organizations: [],
  compagny: '',
  companyId: '',
  preferences: {
    phone: false,
    email: true,
    sms: false,
  },
  accessDemands: false,
  accessDocs: false,
  isSuperAdminTech: false,
}

export interface ContactPreference {
  phone: boolean
  email: boolean
  sms: boolean
}
