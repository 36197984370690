/* eslint-disable class-methods-use-this */
import { ActivitySynthesisWDH } from '../utils/ActivitySynthesisWDH'
import { ActivitySynthesisEntity } from '../utils/ActivitySynthesisEntity'

class ActivitySynthesisNormalizer {
  normalize(
    ActivitySynthesis: ActivitySynthesisWDH[]
  ): ActivitySynthesisEntity[] {
    return ActivitySynthesis.map((item: ActivitySynthesisWDH) => {
      return {
        indicateurCode: item.indicateurCode,
        indicateurLibelle: item.indicateurLibelle,
        annee: item.annee,
        mois: item.mois,
        unite: [
          'ACT-SYNT-05-GES-GE',
          'ACT-SYNT-05-GES-EV',
          'ACT-SYNT-05-GES-CO',
        ].includes(item.indicateurCode)
          ? 't CO₂e'
          : item.unite,
        amount: Number(item.numerateur),
        denominateur: Number(item.denominateur),
        fkfamilledechet: item.fkfamilledechet ? item.fkfamilledechet : '',
        familleDechetCode: item.familleDechetCode ? item.familleDechetCode : '',
        familleDechetLibelle: item.familleDechetLibelle
          ? item.familleDechetLibelle
          : '',
        fkfamilletraitement: item.fkfamilletraitement
          ? item.fkfamilletraitement
          : '',
        familleTraitementCode: item.familleTraitementCode
          ? item.familleTraitementCode
          : '',
        familleTraitementLibelle: item.familleTraitementLibelle
          ? item.familleTraitementLibelle
          : '',
      }
    })
  }
}

export default new ActivitySynthesisNormalizer()
